<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order') }} {{ getOrderId }}
      </h3>
    </portal>
    <skeleton v-if="loading" />
    <rental-sales-form
      v-else
      ref="rental-orders-form"
    />
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/rental-sales/rentalSalesConfig'
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import RentalSalesForm from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesForm.vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import rentalSalesModule from '@/store/main/warehouse/pick/rental-salse'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'RentalSales',
  components: { RentalSalesForm, Skeleton },
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
    }
  },
  computed: {
    getOrderId() {
      return this.$store.state[this.MODULE_NAME].getRentalSalesInformation.order_id
    },
  },
  mounted() {
    this.loading = true
    this.$store.commit(`${this.MODULE_NAME}/RESET_STATE`)
    this.$store.dispatch(`${this.MODULE_NAME}/getRentalSalesOrders`, { order_id: this.$route.params.id }).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.MODULE_NAME}/SET_RENTAL_SALES_INFORMATION`, data)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
      this.loading = false
    })
  },
  created() {
    this.$emit('updateMenu', 'warehouse-rental-sales-pick')
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.$refs['purchase-orders-form']) this.$refs['purchase-orders-form'].showConfirmationModal(next)
  // },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, rentalSalesModule)
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
