var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white px-1 rounded-lg",attrs:{"id":"rental-sales-order-items"}},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order Items'))+" ")]),_c('scan',{attrs:{"asset-item-serial-numbers":_vm.assetItemSerialNumbers},on:{"getValue":_vm.handleCheckBySerialNumber}}),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.getCategoryForTitle())+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"fetched-data":_vm.assetsItem,"isSearchable":false,"is-fullfillment":true,"row-active":{
      filedKey: 'pick_at',
      classes: 'highlightRow'
    }},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex p-0"},[_c('b-form-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate && !_vm.pickedAllItems,"checked":_vm.pickedAllItems},on:{"change":_vm.handleCheckAllItems}})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('b-form-checkbox',{attrs:{"checked":_vm.selectedItems.includes(item.id)},on:{"change":function($event){return _vm.handleSelectedItems(item)}}})],1)]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex pl-1"},[_c('state-of-asset',{attrs:{"data":item,"with-label":false}})],1)]}},{key:"cell(sku)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.sku', '—'))+" ")]}},{key:"cell(name)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'product.name', '—'))+" ")]}},{key:"cell(location)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'location', '—'))+" ")]}},{key:"cell(status)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-1"},[_c('b-form-checkbox',{attrs:{"checked":_vm.pickedItems.includes(item.id)},on:{"change":function($event){return _vm.handleCheckItem($event, item)}}})],1)]}},{key:"cell(inspected)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-1"},[_c('b-form-checkbox',{attrs:{"disabled":!item.pick_at,"checked":_vm.inspectedItems.includes(item.id)},on:{"change":function($event){return _vm.handleInspected($event, item)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }